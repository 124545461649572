<template>
  <div class=''>
    <i class="backup el-icon-caret-top" @click="backup"></i>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
    backup () {
      document.documentElement.scrollTop = 0;
    },
  },
}
</script>

<style scoped lang='scss'>
.backup {
  position: fixed;
  right: 80px;
  bottom: 40px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  cursor: pointer;
  z-index: 5;
}
</style>
